import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { NgIf } from '@angular/common';
import { UserService } from '../../../../services/user.service';




@Component({
  selector: 'redirect-link-external',
  template: `
    <button nz-button nzType="primary" (click)="open()">打开老版本编辑器</button>
  `,
  // standalone: true,
  // imports: [
  //   NzButtonModule,
  //   RouterLink,
  //   NgIf
  // ],
  styles: [
    `

    `
  ]
})
export class RedirectLinkExternal {
  userInfo: any;
  params = {};
  constructor( private activatedRoute: ActivatedRoute ,
               private router: Router ,
               private userService: UserService) {
    this.userInfo = this.userService.getUserInfo();
    // @ts-ignore
    this.params = (new URLSearchParams(this.activatedRoute.routeConfig.params)).toString();
    // this.activatedRoute.title.subscribe({
    //   next: (title) => {
    //   }
    // });
  }

  open() {

    let url = 'http://cartoon.ireadabc.com/home?' + this.params;
    if (window.location.hostname === '127.0.0.1' || window.location.hostname === 'localhost') {
      url = 'http://127.0.0.1:1337/home?' + this.params;
    }
    console.log('open', url);
    window.open( url, '_blank');
  }
}
